<template>
  <div style="margin-top: 70px; overflow: hidden">
    <div class="container-header">
      <b-row>
        <b-col>
          <div class="title-header">
            <h1 class="title">I NOSTRI PRODOTTI DI QUALITÀ!</h1>
          </div>
        </b-col>
      </b-row>
      <b-row style="text-align: center">
        <b-col lg="3">
          <div class="container">
            <b-img
              src="@/assets/images/menu/header/gino6.jpg"
              class="image-header"
            ></b-img>
            <p style="color: black; margin-top: 20px; font-weight: 600">
              Il nostro olio piccante, a differenza del “Tabasco”, viene
              prodotto artigianalmente utilizzando peperoncini Calabria
              essiccati. Volutamente non si usa l’olio extravergine d’oliva in
              quanto non permette un assorbimento corretto del piccante da parte
              dell’olio. Fate attenzione, può causare effetti collaterali 😂
            </p>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="container">
            <b-img
              src="@/assets/images/menu/header/gino5.jpg"
              class="reveal image-header"
            ></b-img>
            <p
              style="color: black; margin-top: 20px; font-weight: 600"
              class="reveal"
            >
              Le nostre cipolle vengono prodotte giornalmente da noi usando
              esclusivamente la variante bionda. Essa viene fatta cuocere a
              fuoco molto lento, per più di 2 ore, dando così la possibilità di
              sprigionare tutta la sua dolcezza. Trova il suo apice di bontà
              abbinata alla peperonata di Carmagnola “Di Vita” con sottiletta o
              scamorza, a seconda della vostra preferenza.
            </p>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="container">
            <b-img
              src="@/assets/images/home/ImageHome.jpg"
              class="reveal image-header"
            ></b-img>
            <p
              style="color: black; margin-top: 20px; font-weight: 600"
              class="reveal"
            >
              La provola silana erroriamente chiamata anche scamorza, viene
              prodotta nella casearia … nel cuore della Sila. Il contatto
              diretto con il produttore e la produzione quasi a livello
              artigianale ci permette di scegliere forma e livello di
              affumicatura portandovi così un formaggio che esalta alla
              perfezione il nostro panino.
            </p>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="container">
            <b-img
              src="@/assets/images/menu/header/gino4.jpg"
              class="reveal image-header"
            ></b-img>
            <p
              style="color: black; margin-top: 20px; font-weight: 600"
              class="reveal"
            >
              La salsiccia del Lard d’Muncalè è la nostra carne di punta. Lard
              d’Muncalè è sinonimo di prodotti di alta qualita, il loro lardo è
              secondo solo a quello di colonnata a livello italiano. La
              salsiccia viene prodotta con parti del maiale selezionate,
              raggiungendo così alti standard qualitativi. Lard d’Muncalè è
              nostro partner sim dall’inizio della nostra attività ed è un capo
              saldo del Panino di Gino.
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="footer">
      <div class="text2">
        <b-row>
          <b-col sm="6">
            <h1 style="color: white">Contattaci</h1>
            <a href="tel:0112076737" class="text-muted">011 207 6737</a>
            <br />
            <a
              href="mailto:info@ginopanino.com"
              class="text-muted"
              style="color: white"
              >info@ginopanino.com</a
            >
          </b-col>
          <b-col sm="6">
            <h1 style="color: white">Seguici sui Social</h1>
            <a
              href="https://www.instagram.com/ginopanino_official/"
              target="_ blank"
              ><img src="@/assets/images/footer/instagram.png" class="icona"
            /></a>
            <a href="https://www.facebook.com/gino.panino.56" target="_ blank"
              ><img src="@/assets/images/footer/facebook.png" class="icona"
            /></a>
            <a
              href="https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D393475267552%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwAR1lDquJMwEjy8LxMxfpNJAXnXeDCBaibLX8auhAJCbGsMnlyXWGdlWrlPg&h=AT2Sv_KA-pj5mDwzFgCYmveh3fsh_8OgjehHqI6FCdD96XCgh6WFlEWIcw026IIljje7J6e27zPWNkbutoT3YRgBQR5c8xizSXDfpjDi07SVOA0Wz45_0Vt-STmOA1BUSKb2QSX7JPWml1JQ_4oquWsc"
              target="_ blank"
              ><img src="@/assets/images/footer/whatsapp.png" class="icona"
            /></a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Burger from "@/components/animated-images/street-food/burger.vue";
import PaniniCard from "../components/menu/panini-card.vue";
import PatatineCard from "@/components/menu/patatine-card.vue";
import BibiteCard from "@/components/menu/bibite-card.vue";
export default {
  components: { Burger, PaniniCard, PatatineCard, BibiteCard },
  data() {
    return {
      relatedProducts: [
        {
          name: "Il Liscio",
          image: require("@/assets/images/menu/panini/Liscio.jpg"),
          ingredienti: "Salsiccia d’Muncalè o Würstel e una salsa a scelta",
          prezzo: 4.5,
        },
        {
          name: "Il Pigiamino",
          image: require("@/assets/images/menu/panini/Pigiamino.jpg"),
          ingredienti: "Salsiccia d’Muncalè, Formaggio e cipolla caramellata",
          prezzo: 5.5,
        },
        {
          name: "Il Nutrizionista",
          image: require("@/assets/images/menu/panini/Pigiamino.jpg"),
          ingredienti:
            "Due fette di petto di pollo, Formaggio Light, verdure grigliate, Ketchup Zero, Maionese Light",
          prezzo: 6.5,
        },
        {
          name: "Il Fortunello",
          image: require("@/assets/images/menu/panini/Pigiamino.jpg"),
          ingredienti:
            "Salsiccia d’Muncalè, Formaggio, Cipolla caramellata, Peperoni di Carmagnola, ketchup, maionese(entrambi Heinz) e Nduja di Spilinga",
          prezzo: 6.9,
        },
        {
          name: "Lo Spinagino",
          image: require("@/assets/images/menu/panini/Spinagino.jpg"),
          ingredienti:
            "Salsiccia d’Muncalè, 4 fette di Scamorza affumicata calabra, foglie di spinaci e maionese",
          prezzo: 6.9,
        },
        {
          name: "La Bestia",
          image: require("@/assets/images/menu/panini/Bestia.jpg"),
          ingredienti:
            "Salsiccia d’Muncalè, 4 fette di scamorza affumicata calabra, 4 fette di Porchetta, Ketchup e Maionese (entrambi Heinz)",
          prezzo: 8.5,
        },
        {
          name: "Lo Sgarro (1/2 KG)",
          image: require("@/assets/images/menu/panini/Sgarro.jpg"),
          ingredienti:
            "Doppia salsiccia d’Muncalè (240g), 8 fette di scamorza affumicata (130g), 4 fette di porchetta(130g), Ketchup, Maio e piccante",
          prezzo: 12.7,
        },
        {
          name: "Il Vegetariano",
          image: require("@/assets/images/menu/panini/Sgarro.jpg"),
          ingredienti:
            "Salsiccia Plant-Based, Formaggio Vegano e verdure grigliate",
          prezzo: 7.9,
        },
      ],
    };
  },
};
</script>

<style scoped>
.image-header {
  height: 250px;
  border-radius: 20%;
}
.list-container {
  padding-left: 100px;
  padding-right: 100px;
}
.list-name {
  color: black;
  font-size: large;
  font-weight: 600;
}
.list-ing {
  padding-left: 10px;
}
.list-price {
  float: right;
}
.container-header {
  margin-top: 50px;
  margin-bottom: 50px;
}
.title-header {
  margin-left: 350px;
  margin-right: 350px;
  margin-bottom: 50px;
  text-align: center;
}
.title {
  color: black;
  font-size: 30px;
  font-weight: 800;
}
.text-container {
  padding-right: 70px;
  padding-left: 70px;
  line-height: 30px;
}

.container {
  margin-bottom: 0px;
  text-align: center;
}
@media (min-width: 991px) {
  .reveal {
    position: relative;
    transform: translateY(0px);
    opacity: 1;
    transition: 1s all ease;
  }
}

@media (max-width: 991px) {
  .title-header {
    margin-left: 20px;
    margin-right: 20px;
  }
  .title {
    font-size: 20px;
    font-weight: 800;
  }
  .list-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .text-container {
    padding-right: 20px;
  }
  .container {
    margin-bottom: 80px;
    text-align: center;
  }
}

.subtitle {
  color: black;
  font-size: 30px;
  font-weight: 400;
}

.text {
  color: black;
  font-size: 20px;
  font-weight: 300;
}

.section {
  color: black;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}
</style>

<style scoped>
.footer {
  width: 100%;
  height: 270px;
  background-image: url("../assets/images/footer/background-footer-blue.png");
  background-size: 100% 100%;
  text-align: center;
  overflow: hidden;
}

.text2 {
  padding-top: 100px;
  color: white;
}
.icona {
  width: 30px;
  margin-right: 20px;
}
</style>